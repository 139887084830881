<template>
  <n-page v-if="renderComponent">
    <a-row type="flex" class="justify-content-end">
      <a-col :span="24" :xs="24" :sm="12" :md="8" class="gx-text-right">
        <div class="butn_group adminlist_btns">
          <a-button
            type="primary"
            size="small"
            class="ant-btn ant-btn-sm ant-btn-block ant-btn ant-btn-primary ant-btn-sm"
            @click="openAdminModal(null)"
          >
            Create a new Coach
          </a-button>
          <a-button
            size="small"
            type="primary"
            class="ant-btn ant-btn-sm ant-btn-block ant-btn ant-btn-primary ant-btn-sm"
            @click="openCoachModal"
            >Find & Add Coach</a-button
          >
        </div>
      </a-col>
    </a-row>
    <a-row type="flex">
      <a-col :span="24">
        <a-card title="Coaches" class="gx-card-table-full">
          <club-admins-list-table @edit="openAdminModal" />
        </a-card>
      </a-col>
    </a-row>
    <club-admin-edit-modal
      :admin-id="currentAdmin"
      :visible="clubAdminModal"
      @close="closeAdminModal"
    />
    <find-coach-modal :visible="findCoachModal" @close="closeAdminModal" />
  </n-page>
</template>

<script>
import NPage from "@/components/ui/n-page/n-page";
import ClubAdminsListTable from "@/components/club-admins/club-admins-list-table";
import ClubAdminEditModal from "@/components/club-admins/club-admin-edit-modal";
import FindCoachModal from "@/components/club-admins/find-coach-modal";
export default {
  name: "ClubAdmins",
  components: {
    ClubAdminEditModal,
    ClubAdminsListTable,
    NPage,
    FindCoachModal,
  },
  data() {
    return {
      currentAdmin: null,
      renderComponent: true,
      clubAdminModal: false,
      findCoachModal: false,
    };
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    openAdminModal(id) {
      this.currentAdmin = id;
      this.clubAdminModal = true;
    },
    openCoachModal() {
      this.findCoachModal = true;
    },
    closeAdminModal() {
      this.currentAdmin = null;
      this.clubAdminModal = false;
      this.findCoachModal = false;
      this.forceRerender();
    },
  },
};
</script>

<style scoped>
.butn_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.adminlist_btns {
  width: 100%;
}
.justify-content-end {
  justify-content: end;
}
</style>
